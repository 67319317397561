<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <div class="container">
      <router-link class="navbar-brand" to="/">Gex</router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
          <li v-if="isAdmin" class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
              aria-expanded="false">
              Admin
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><a class="dropdown-item" href="/cadastro">Cadastros</a></li>
              <li><a class="dropdown-item" href="/lista-usuarios">Lista de usuários</a></li>
            </ul>
          </li>
        </ul>
        <button class="btn btn-danger ms-auto" @click="logout">Logout</button>
      </div>
    </div>
  </nav>
</template>

<script>
import { jwtDecode } from 'jwt-decode';

export default {
  data() {
    return {
      isAdmin: false
    }
  },
  created() {
    this.checkAdmin();
  },
  methods: {
    checkAdmin() {
      const token = window.localStorage.getItem("auth_token");
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          this.isAdmin = decodedToken.scope === "ADMINISTRADOR";
        } catch (error) {
          console.error("Erro ao decodificar o token", error);
          this.isAdmin = false;
        }
      }
    },
    logout() {
      window.localStorage.removeItem("auth_token");
      this.$router.push('/login');
    }
  }
}
</script>

<style scoped>
.navbar {
  z-index: 101;
  position: sticky;
  top: 0;
  width: 100%;
  padding: 10px 20px;
}

.container {
  max-width: 100%;
}

.nav-item {
  margin: 0 10px; 
}

.btn {
  margin-left: auto;
}
</style>
