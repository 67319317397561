<template>
    <form class="form-entrar" @submit.prevent="enviar">
        <div>
            <button @click.prevent="$emit('fechar-componente')" style="border: none; background-color: white; text-decoration: none; color: black">
                < voltar</button>
        </div>
        <h3>Primeiro acesso</h3>
        <input class="input-login" type="text" placeholder="Digite aqui seu CPF" @keyup="verificarCpf"
            v-maska="'###.###.###-##'" v-model="cpf" name="cpf" autofocus required />
        <input v-show="!emailEnviado" class="submit-login" type="submit" value="Enviar" :disabled="!cpfCorreto" />
        
        <div v-show="emailEnviado">
            <div class="container height-100 d-flex justify-content-center align-items-center">
                <div class="position-relative">
                    <div id="div-validacao" class="card p-2 text-center">
                        <h6>Um código de verificação foi enviado para seu email</h6>
                        <div>
                            <span>O código foi enviado para o email:</span> <small>{{ email }}</small>
                        </div>
                        <div id="otp" class="inputs d-flex flex-row justify-content-center mt-2">
                            <input class="m-2 text-center form-control rounded" type="text" v-model="codigo[0]" maxlength="1" name="codigo1" />
                            <input class="m-2 text-center form-control rounded" type="text" v-model="codigo[1]" maxlength="1" name="codigo2" />
                            <input class="m-2 text-center form-control rounded" type="text" v-model="codigo[2]" maxlength="1" name="codigo3" />
                            <input class="m-2 text-center form-control rounded" type="text" v-model="codigo[3]" maxlength="1" name="codigo4" />
                            <input class="m-2 text-center form-control rounded" type="text" v-model="codigo[4]" maxlength="1" name="codigo5" />
                            <input class="m-2 text-center form-control rounded" type="text" v-model="codigo[5]" maxlength="1" name="codigo6" />
                        </div>

                        <input class="input-login mt-3" type="password" placeholder="Nova Senha" v-model="novaSenha"  name="novaSenha" />
                        <input class="input-login mt-3" type="password" placeholder="Confirme a Nova Senha" v-model="confirmarSenha"  name="confirmarSenha" />

                        <div class="mt-4">
                            <button class="submit-login">Validar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import LoginLayout from '@/layouts/LoginLayout.vue';
import { vMaska } from 'maska/vue';

export default {
    data() {
        return {
            cpf: '',
            cpfCorreto: false,
            primeiroLogin: LoginLayout.primeiroLogin,
            emailEnviado: false,
            codigo: ['', '', '', '', '', ''],
            novaSenha: '',
            confirmarSenha: '',
            email: ''
        };
    },
    methods: {
        verificarCpf() {
            this.cpfCorreto = this.cpf.length === 14;
        },
        async enviar() {
            if (this.emailEnviado) {
                if (this.novaSenha === this.confirmarSenha) {
                    const pincode = this.codigo.join('');
                    const cpfSemMascara = this.cpf.replace(/\D/g, '');
                    
                    const response = await this.$api.post('controle-acesso/valid', { emailOrCpf: cpfSemMascara, pinCode: pincode, senha: this.novaSenha });
                    if (response.status === 200) {
                        alert("Senha alterada com sucesso.");
                        this.$emit('fechar-componente-sucesso-senha-alterada');
                    }
                } else {
                    alert("As senhas não coincidem.");
                }
            } else {
                if (this.cpfCorreto) {
                    try {
                        const cpfSemMascara = this.cpf.replace(/\D/g, '');
                        const response = await this.$api.post('controle-acesso/generate', { emailOrCpf: cpfSemMascara });
                        if (response.status === 202) {
                            this.emailEnviado = true;  
                        } else {
                            alert("Erro ao enviar o email. Tente novamente.");
                        }
                    } catch (error) {
                        alert("Erro ao enviar o email. Tente novamente.");
                    }
                } else {
                    alert("Para enviar, digite seu CPF com todos os números.");
                }
            }
        }
    },
    directives: {
        maska: vMaska,
    },
    emits: ['fechar-componente', 'fechar-componente-sucesso-senha-alterada'],
};
</script>

<style scoped>
.form-entrar h3 {
    padding: 0;
    margin: 0;
    font-size: 40px;
    color: #02564d;
}

.submit-login[disabled] {
    background-color: rgb(208, 208, 208);
}
</style>
