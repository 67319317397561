<template>
  <div id="menu">
    <div class="user-info">
      <h5>{{ userName }}</h5>
    </div>
    <div class="list-group mt-4">
      <button
        v-for="item in menu"
        :key="item.label"
        class="list-group-item list-group-item-action"
        @click="emitirPagina(item.page)"
      >
        {{ item.label }}
      </button>
    </div>
  </div>
</template>

<script>
import { jwtDecode } from 'jwt-decode';
import eventBus from '@/eventBus';

export default {
  data() {
    return {
      userName: null,
      menu: [],
    };
  },
  created() {
    this.initializeMenu();
  },
  methods: {
    initializeMenu() {
      const token = window.localStorage.getItem('auth_token');
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          this.userName = decodedToken.sub;
          this.setMenuForUser(decodedToken.scope);
        } catch (error) {
          console.error('Erro ao decodificar o token:', error);
        }
      }
    },
    setMenuForUser(scope) {
      if (scope === 'ADMINISTRADOR') {
        this.menu = [
          { label: 'Suas Ações', page: 'acoes' },
          { label: 'Atividades', page: 'atividades' },
          { label: 'Notícias', page: 'noticias' },
          { label: 'Meus Certificados', page: 'certificados' },
        ];
      } else {
        this.menu = [
          { label: 'Atividades', page: 'atividades' },
          { label: 'Notícias', page: 'noticias' },
        ];
      }
    },
    emitirPagina(pagina) {
      eventBus.emit('changeComponent', pagina);
    }
  }
};
</script>

<style scoped>
#menu {
  background-color: #343a40;
  color: #ffffff;
  width: 20%;
  padding: 20px;
  overflow-y: auto;
}

.user-info {
  margin-top: 4vh;
  margin-bottom: 2vh;
  font-size: 1.2rem;
}

button {
  background-color: transparent;
  border: none;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 15px;
  min-height: 50px;
  width: 100%;
  border-radius: 5px;
  transition: background-color 0.3s;
}

button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
</style>
