import axios from 'axios';

function interceptors() {
    const config = setting => setting

    const error = erro => {
      const responseErros = {
        401: "Não autorizado, precisa se autenticar!",
        403: "Recurso proibido para o tipo de acesso!"
      };
      const extractMessage = erro?.response?.data?.message || null;
      const statusCode = erro?.response?.status;

      if (statusCode === 401 || extractMessage?.match(/token inválido|token expirado/i)) {
        window.localStorage.removeItem("auth_token");
        window.postMessage("login-expirado");
      }

      if (responseErros[statusCode]) {
        console.error(responseErros[statusCode]);
      }

      return Promise.reject(erro.response);
    };

    return { config, error };
}

const api = () => {
    const token = window.localStorage.getItem("auth_token");
    const apiBaseUrl = "https://gex.nerds.cloudns.be/api";

    const configAxios = axios.create({
      baseURL: apiBaseUrl,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        ...(token ? { 'Authorization': `Bearer ${token}` } : {}),
      },
    });

    const { config, error } = interceptors();
    configAxios.interceptors.request.use(config);
    configAxios.interceptors.response.use(response => response, error);

    return configAxios;
}

export const apiPlugin = {
    install(app, options) {
      app.config.globalProperties.$api = api();
    }
}
