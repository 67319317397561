<template>
    <div v-show="!atividadeSelecionada">
        <div id="suas-atividades">
            <h3>Suas atividades</h3>
            <div class="conteudo">
                <button @click="atividadeSelecionada = true">Suas Atividades</button>
            </div>
            <div class="conteudo">
                <button @click="atividadeSelecionada = true">Suas Atividades</button>
            </div>
            <div class="conteudo">
                <button @click="atividadeSelecionada = true">Suas Atividades</button>
            </div>
        </div>
        <div id="atividades-livres">
            <h3>Atividades livres</h3>
            <div class="conteudo">
                <button @click="atividadeSelecionada = true">Atividades Livres</button>
            </div>
            <div class="conteudo">
                <button @click="atividadeSelecionada = true">Atividades Livres</button>
            </div>
            <div class="conteudo">
                <button @click="atividadeSelecionada = true">Atividades Livres</button>
            </div>
        </div>
        <div id="incricoes-abertas">
            <h3>Atividades com inscrição</h3>
            <div class="conteudo">
                <button @click="atividadeSelecionada = true">Atividades com inscrição</button>
            </div>
            <div class="conteudo">
                <button @click="atividadeSelecionada = true">Atividades com inscrição</button>
            </div>
            <div class="conteudo">
                <button @click="atividadeSelecionada = true">Atividades com inscrição</button>
            </div>
        </div>
    </div>
    <div v-show="atividadeSelecionada">
        <AtividadeView @fechar-atividade="atividadeSelecionada = false"/>
    </div>
</template>
<script setup>
import {ref} from 'vue';
import AtividadeView from '@/views/AtividadeView.vue';

var atividadeSelecionada = ref(false);
</script>
<style scoped></style>