
<template>
    <div v-show="!noticiaSelecionada">
        <div class="conteudo">
            <button @click="noticiaSelecionada = true">Noticia 1</button>
            <p>xx/xx/xxxx</p>
        </div>
        <div class="conteudo">
            <button @click="noticiaSelecionada = true">Noticia 2</button>
            <p>xx/xx/xxxx</p>
        </div>
        <div class="conteudo">
            <button @click="noticiaSelecionada = true">Noticia 3</button>
            <p>xx/xx/xxxx</p>
        </div>
        <div class="conteudo">
            <button @click="noticiaSelecionada = true">Noticia 4</button>
            <p>xx/xx/xxxx</p>
        </div>
        <div class="conteudo">
            <button @click="noticiaSelecionada = true">Noticia 5</button>
            <p>xx/xx/xxxx</p>
        </div>
    </div>
    <div v-show="noticiaSelecionada">
        <NoticiaView @fechar-noticia="noticiaSelecionada = false"/>
    </div>
</template>

<script setup>
import NoticiaView from '@/components/Noticia.vue';
import {ref} from 'vue';

var noticiaSelecionada = ref(false);
</script>
