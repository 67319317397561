<template>
    <div id="conteudo-acao">
        <div id="botao-voltar">
            <button @click="$emit('fechar-acao')">< voltar</button>
        </div>

        <div id="titulo">
            <h2>Nome da Ação</h2>
            <h4>Coordenador:</h4>
        </div>
        <div id="menus">
            <div class="items">
                <a class="btn btn-primary" data-bs-toggle="collapse" href="#atividades" role="button"
                    aria-expanded="false" aria-controls="collapseExample">
                    Atividades(Da ação)
                </a>
                <div class="collapse" id="atividades">
                    <div class="card card-body">
                        Atividades disponiveis
                    </div>
                </div>
            </div>
            <div class="items">
                <a class="btn btn-primary" data-bs-toggle="collapse" href="#noticias" role="button"
                    aria-expanded="false" aria-controls="collapseExample">
                    Noticias(Da ação)
                </a>
                <div class="collapse" id="noticias">
                    <div class="card card-body">
                        Noticias da ação
                    </div>
                </div>
            </div>
            <div class="items">
                <a class="btn btn-primary" data-bs-toggle="collapse" href="#informacoes" role="button"
                    aria-expanded="false" aria-controls="collapseExample">
                    informações
                </a>
                <div class="collapse" id="informacoes">
                    <div class="card card-body">
                        Informações da ação
                    </div>
                </div>
            </div>
            <div class="items">
                <a class="btn btn-primary" data-bs-toggle="collapse" href="#tarefas" role="button" aria-expanded="false"
                    aria-controls="collapseExample">
                    Meus certificados
                </a>
                <div class="collapse" id="tarefas">
                    <div class="card card-body">
                        Meus certificados obtidos
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
#menus {
    display: flex;
    flex-direction: column;
    padding: 5%;
    height: max-content;
}

.items{
    margin-bottom: 2%;
}

.items a{
    background-color: #e9e9e9;
    border-color: grey;
    color: black;
    font-size: 1.5rem;
    font-weight: 500;
    text-align: left;
    width: 100%;
    border-radius: 1.25rem;
}

.items a:active{
    background-color: rgb(68, 68, 68);
    color: white;
}

#titulo{
    margin-top: 1%;
    text-align: center;
}

.card{
    border-radius: 1.25rem;
}

#conteudo-acao{
    display:flex;
    flex-direction: column;
}
</style>