<template>
  <div>
    <div v-show="pagina == 'acoes'">
      <Acoes />
    </div>
    <div v-show="pagina == 'atividades'">
      <Atividades />
    </div>
    <div v-show="pagina == 'noticias'">
      <Noticias />
    </div>
  </div>
</template>

<script>
import { jwtDecode } from 'jwt-decode'
import eventBus from '@/eventBus';
import Acoes from '@/components/MenuAcoes.vue'
import Atividades from '@/components/Atividades.vue'
import Noticias from '@/components/MenuNoticias.vue'

export default {
  data() {
    return {
      userName: null,
      pagina: '',
      posicaoY: 50
    }
  },
  methods: {
    atualizarPagina(novaPagina) {
      this.pagina = novaPagina;
    },

    subirDivMenu() {
      const scroll = window.scrollY;
      this.posicaoY = Math.max(0, 50 - scroll);
    }
  },

  mounted() {
    window.addEventListener("scroll", this.subirDivMenu); // Adiciona o listener de scroll quando o componente for montado
  },

  beforeUnmount() {
    window.removeEventListener("scroll", this.subirDivMenu); // Remove o listener de scroll antes de desmontar o componente
  },

  created() {
    eventBus.on('changeComponent', this.atualizarPagina);

    const token = window.localStorage.getItem('auth_token')
    if (token) {
      try {
        const decodedToken = jwtDecode(token)
        this.userName = decodedToken.sub
      } catch (error) {
        console.error('Erro ao decodificar o token:', error)
      }
    }
  },
  beforeUnmount() {
    eventBus.off('changeComponent', this.atualizarPagina);
  },
  components: {
    Acoes,
    Atividades,
    Noticias
  }
}
</script>

<style scoped>
.container-pai {
  display: flex;
  padding: 0;
  margin-left: 0;
  margin-right: 0;
  height: 95vh;
}

#menu {
  display: flex;
  position: fixed;
  top: 0;
  padding: 50px 2% 0 2%;
  height: 100%;
  width: 20vw;
  flex-direction: column;
  background-color: #e9e9e9;
}

#menu button {
  margin-top: 20px;
  border: none;
  background-color: #e9e9e9;
  border-bottom: solid black 2px;
  margin-bottom: 5%;
}

#conteudo {
  display: flex;
  flex-direction: column;
  width: 100vw;
  padding: 5%;
  margin-left: 20vw;
  padding-top: 2%;
}
</style>
<style>
.conteudo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #e9e9e9;
  margin-bottom: 2%;
  border-radius: 2rem;
}

.conteudo p {
  margin: 0 2% 0 1%;
}

.conteudo button{
  border: none;
  border-radius: 1.95rem;
  text-align: left;
  display: block;
  width: 100%;
  font-weight: 500;
  color: black;
  padding: 2%;
  font-size: 1.95rem;
}

#botao-voltar button{
    border: none;
    background-color: #e9e9e9;
    border-radius: 1rem;
}

#botao-voltar{
  margin-left: 5%;
}
</style>