<template>
    <div v-show="!acoesEncerradas && !acaoSelecionada">
        <div class="conteudo">
            <button @click.prevent="acaoSelecionada = true" >Ação 1</button>
        </div>
        <div class="conteudo">
            <button @click.prevent="acaoSelecionada = true" >Ação 2</button>
        </div>
        <div class="conteudo">
            <button @click.prevent="acaoSelecionada = true" >Ação 3</button>
        </div>
    </div>
    <div id="botao-acoes-encerradas" v-show="!acaoSelecionada">
        <button @click="acoesEncerradas = !acoesEncerradas">
            <h4 v-show="!acoesEncerradas">ver ações encerradas</h4>
            <h4 v-show="acoesEncerradas">ver ações ativas</h4>
        </button>
    </div>
    <div v-show="acoesEncerradas && !acaoSelecionada">
        <AcoesEncerradas @abrir-acao="acaoSelecionada = true"/>
    </div>
    <div v-show="acaoSelecionada">
        <AcaoView @fechar-acao="acaoSelecionada = false"/>
    </div>
</template>
<script setup>
import AcoesEncerradas from './AcoesEncerradas.vue';
import AcaoView from '@/components/Acao.vue';
import { ref } from 'vue'

var acoesEncerradas = ref(false);
var acaoSelecionada = ref(false);
</script>
<style scoped>
#botao-acoes-encerradas button {
    position: fixed;
    bottom: 0;
    right: 0;
    border: none;
    background-color: #6c757d;
    color: #e9e9e9;
    border-bottom: solid rgb(163, 163, 163) 2px;
    border-top-left-radius: 1rem;
}
</style>